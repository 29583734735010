import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgPlasticBusPass from "../images/plastic-school-bus-pass-photo-id.jpg"
import imgDogWaitingForBusPass from "../images/tasty-school-bus-pass-for-dog.jpg"
import imgMobileTicket from "../images/mobile-ticket-for-school-transport.jpg"
import imgSaveTime from "../images/save-time-money-using-shuttleid.jpg"
import imgScreenCracked from "../images/mobile-screen-cracked.jpg"
import imgSafeguarding from "../images/safeguarding.jpg"
import imgProfileChrisBell from "../images/chris-bell-profile-square.jpg"
import "./index.css";
import "./case-study.css";
import "./article.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from "../components/nav"
import Footer from "../components/footer"
import Contact from "../components/contact"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Ticket scanner demo" />
        <Nav activeTab="blog" />
        <div>
          <div>

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">Ticket scanner demo</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">Ticket scanner demo</h1>
              </div>

              <div className="case-study" style={{ paddingTop: 1 }}>

                <div className="article">
                  <div className='article-intro article-inner'>

                    <p style={{ margin: 0 }}>Do you have an idea who <em>should</em> be travelling, but don't actually know who <em>is</em> travelling? Watch the video below to see how ShuttleID can make your school bus safer and also help protect your revenue.</p>
                    <div style={{ padding: '64.63% 0 0 0', position: 'relative' }}><iframe src="https://player.vimeo.com/video/711739681?h=1646c4231c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullscreen style={{ position: 'absolute', top:0, left:0, width: '100%', height: '100%' }} title="ShuttleID - Ticket scanning demo"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                    <p>In this short clip Chris Bell, ShuttleID director, demonstrates:</p>
                    <ul>
                      <li>[00:31] How fast the scanner is at reading a school bus pass</li>
                      <li>[02:15] How it handles a student boarding the wrong bus</li>
                      <li>[03:25] How it handles pass sharing and ticket fraud</li>
                    </ul>
                    <p>The ticket scanner protects your school bus from <strong>9 types of invalid pass use</strong>, and all in <strong>less than one second</strong>:</p>
                    <ul>
                      <li>Duplicated pass</li>
                      <li>Voided pass</li>
                      <li>Fake pass</li>
                      <li>Expired pass</li>
                      <li>Not yet valid pass</li>
                      <li>Pass used on wrong day</li>
                      <li>Pass used on wrong service</li>
                      <li>Pass used at wrong time e.g. am or pm only tickets</li>
                      <li>Pass in negative balance (flexi)</li>
                    </ul>


                    <p><Link className="link" to="/contact/">Contact us</Link> for more information and to arrange a full demo.</p>
                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage